import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Switch, Case, Default, When } from 'react-if';

import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import Header from '../../../components/theme/Header'
import ImageHeader from '../../../components/theme/ImageHeader'
import RenderBlock from '../../../components/blocks/RenderBlock'
import sendEvent from '../../../helpers/sendAnalytics';
import Offers from '../../../components/common/Offers';
import PaperHeader from '../../../components/theme/PaperHeader'

class Category extends Component {
  componentWillMount() {
    const { pageContext } = this.props
    const { guid } = pageContext

    const events = {
      fb: 'ViewContent',
      snapchat: 'VIEW_CONTENT'
    };

    switch (guid) {
      // BATHROOM TISSUE
      case '3f28be5e-63ac-434b-9371-8511b5defcaf':
        events.gtag = {
          send_to: 'AW-951001886/vWAMCLvygZMBEJ7GvMUD'
        };
        events.appnexus = 'https://secure.adnxs.com/px?id=1118273&seg=18016672&t=2';
        break;
      // PAPER TOWEL
      case 'fc61d565-257d-40b3-b1c4-34cac3f03193':
        events.gtag = {
          send_to: 'AW-951001886/pCEuCNbX-pIBEJ7GvMUD'
        };
        events.img = ['https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:gtjt74b&fmt=3'];
        break;
      // FACIAL TISSUE
      case '50063444-203d-4baf-8e1e-629613766fa1':
        break;
      default:
    }

    // EVENT: BuyOnline
    sendEvent(events)
  }

  render() {
    const { data } = this.props

    // TEMP HACK UNTIL PRODUCTS DONE
    // SV
    if (data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193') {
      data.productCategoryPage.description = '<p>Every great Canadian BBQ starts</p>\n' +
        '<p>with Tiger Strong<sup>&trade;</sup> ROYALE<sup>&reg;</sup> Tiger&nbsp;Towel<sup>&reg;</sup></p>';
      data.productCategoryPage.image_url = 'https://royale.imgix.net/contentassets/18a8bf14495c4eb0b8a3db6a80bf546b/bbq_header_image.jpg';
    }
    // END OF HACK
    // SV

    let type = '';
    if (data.productCategoryPage.guid === '3f28be5e-63ac-434b-9371-8511b5defcaf') {
      type = 'bathroom';
    } else if (data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193') {
      type = 'papertowel';
    }

    return (
      <Layout
        meta={data.productCategoryPage.meta}
        urls={data.productCategoryPage.urls}
        locale='en'
      >
        <Header
          urls={data.productCategoryPage.urls}
          locale='en'
        />

        <When condition={data.productCategoryPage.guid === '3f28be5e-63ac-434b-9371-8511b5defcaf' || data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193'}>
          <PaperHeader type={type} />
        </When>

        {data.productCategoryPage.guid !== '3f28be5e-63ac-434b-9371-8511b5defcaf' && data.productCategoryPage.guid !== 'fc61d565-257d-40b3-b1c4-34cac3f03193' ?
          (
            <ImageHeader
              guid={data.productCategoryPage.guid}
              title={data.productCategoryPage.description}
              image={data.productCategoryPage.image_url}
              alt={data.productCategoryPage.title_html}
            />
          ) : null }

        <RenderBlock blocks={data.productCategoryPage.blocks} />
        <Switch>
          <Case condition={data.productCategoryPage.guid === '3f28be5e-63ac-434b-9371-8511b5defcaf'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='*Than our Royale<sup>®</sup> Original Brand. <br><br>Each year, forests managed by J.D. Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup> tissue products.'  />
          </Case>
          <Case condition={data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='Each year, forests managed by J.D. Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup> tissue products.<br/><br/>*When comparing new double roll packs against previous single roll packs ' />
          </Case>
          <Case condition={data.productCategoryPage.guid === '50063444-203d-4baf-8e1e-629613766fa1'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='*Each year, forests managed by J.D Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup> tissue products.' />
          </Case>
          <Case condition={data.productCategoryPage.guid === 'be15610d-872f-4d9e-bb90-d93abc9d844b'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='*Each year, forests managed by J.D Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup> tissue products.' />
          </Case>
          <Default>
            <Footer image={data.productCategoryPage.footer_image} />
          </Default>
        </Switch>
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query categoryPage($guid: String!) {
  productCategoryPage(guid: { eq: $guid }, locale: {eq:"en"}) {
    guid
    title
    description
    footer_image
    image_url
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...productLandingBlockFragment

      ... on ProductCategoryBlock {
        guid
        id
        modification
        description
        video
        product_header
        logo_image_url
        product_category_image_url
        products {
          id
          title
          title_html
          guid
          images_url
        }
      }
    }
    other_category_1 {
      preview_image_url
      title
      guid
    }
    other_category_2 {
      preview_image_url
      title
      guid
    }
    other_category_3 {
      preview_image_url
      title
      guid
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Category
